/**
 * @generated SignedSource<<c4dcc76cfb1016ef2cc1848e2a7d56d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderInlineDataFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type CreateIssueDiscussion$data = {
  readonly formattedBody: string | null | undefined;
  readonly labels: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"LabelPickerLabel">;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly title: string;
  readonly " $fragmentType": "CreateIssueDiscussion";
};
export type CreateIssueDiscussion$key = {
  readonly " $data"?: CreateIssueDiscussion$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateIssueDiscussion">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "CreateIssueDiscussion"
};

(node as any).hash = "3dfe7728cf1710c087c3df24377c8591";

export default node;
